.slick-prev {
  left: 3% !important;
  z-index: 1;
}

.slick-next {
  right: 3% !important;
  z-index: 1;
}

.life-chapters-container {
  margin: 4% 4%;
  overflow: hidden;
}

.tab-button:hover {
  text-decoration: underline;
  text-decoration-color: #fac504;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.active-tab {
  /* background-color: #d3d3d3; */
  background-color: transparent;
  font-family: Poppins, sans-serif;
  border-color: transparent;
  font-weight: bolder;
  text-decoration: underline;
  text-decoration-color: #fac504;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
  margin-top: 15px;
  line-height: 1.5; /*  /* Gray out the active tab */
}

.active-tab .material-symbols-outlined {
  vertical-align: middle; /* Align the bottom of the icon with the baseline of the text */
  margin-right: 8px; /* Optional: adds some space between the icon and the text */
}

.header {
  background-color: #fff;
}

.nav-list {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 50%;
}

.nav-link {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
}

/* TestDemo.css */
.item.center {
  transform: scale(1.1); /* Make the center item larger */
  transition: transform 0.5s ease, filter 0.5s ease, opacity 0.5s ease;
  z-index: 10; /* Ensure it sits above other slides */


.carousel-container {
    width: 100%;
    max-width: 1200px; /* Adjust based on your layout needs */
    margin: 0 auto;
    padding: 0 20px; /* Gives some breathing room on smaller screens */
  }}

.carousel-container .slick-slide {
  transition: transform 0.5s ease, filter 0.5s ease, opacity 0.5s ease;
  margin-top: 7rem;
  margin-bottom: 10rem; /* Adjust as needed */
  margin-right: 0rem;
}

.slide-slider {
  height: auto;
}

.learn-more {
  display: none; /* Hide button by default */
  margin-top: 10px;
}

.item.center .learn-more {
  display: block; /* Only display button in the center slide */
}

.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.15);  /* Slightly transparent white */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);  /* For Safari support */
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  border-top: 1px solid rgba(255, 255, 255, 0.2);  /* Subtle top border */
}

.banner span {
  flex-grow: 1;
}

.sign-out-button {
  background-color: #fac504;
  color: #000000;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sign-out-button:hover {
  background-color: #f5b400;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

/* Fallback for browsers that don't support backdrop-filter */
@supports not (backdrop-filter: blur(10px)) {
  .banner {
    background-color: rgba(0, 0, 0, 0.8);  /* More opaque fallback */
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.tab-button {
  font-family: Poppins, sans-serif;
  background-color: transparent;
  border-color: transparent;
  font-weight: bolder;
  margin-top: 15px;
  line-height: 1.5; /* Adjusts the height of lines in the button, can help with alignment */
  text-decoration: underline;
  text-decoration-color: #000000;
  text-decoration-thickness: 3px;
  text-underline-offset: 6px;
}

.demo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 20px 5%;
  background-color: #faf3e0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.demo-main-content {
  margin-top: 0px;
}

.demo-header-logo {
  font-family: Palatino Linotype, serif;
  font-size: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}



.photo-and-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: right;
  flex-grow: 1;
  gap: 2.5rem; /* Add more space between elements */
}

.demo-name {
  font-size: 2rem;
  font-weight: bold;
  color: #ffffff;
}


.search-box {
  font-family: "Poppins", sans-serif;
}

.search-button {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  background-color: #fac504;
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 7rem;
}

.search-button:hover {
  background-color: #f5b400;
}

.tab-button .material-symbols-outlined {
  vertical-align: middle; /* Align the bottom of the icon with the baseline of the text */
  margin-right: 8px; /* Optional: adds some space between the icon and the text */
}

.tabs {
  display: flex;
  flex-direction: row;
  width: 80%;
  align-items: space-between;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 10px;
  align-items: center;
}

.tabs-holder {
  margin-top: 10px;
  align-items: center;
}

.toggle-results-button {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  background-color: #fac504;
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.toggle-results-button:hover {
  background-color: #f5b400;
}

.demo-page {
  background-color: #f5f5f5;
  overflow: hidden;
  height: 100vh;
}

.vertical-image-holder {
  display: flex;
  flex-direction: column;
  margin: 0px 10px;
  width: 30%;
}

.overall-image-holder {
  display: flex;
  flex-direction: row;
  align-items: space-between;
  justify-content: center;
  overflow-y: auto;
  height: 90%;
  padding: 40px;
  /*width: 100%;  Ensure this container is the full width it's allowed, but no more */
  /*max-width: 100vw; /* Prevents the container from extending beyond the view width */
}

.vertical-image-holder img {
  margin-bottom: 10px;
}

.family-tree-photo {
  height: 85%;
  width: auto;
  margin-top: 1%;
  overflow: hidden;
}

.white-box-holder {
  position:relative;
  z-index: 2;
  background-color: white;
  border-color: black;
  width: 80%;
  margin: 0 auto;
  border-radius: 25px;
  height: 75vh;
  overflow: auto; /* Ensures that anything outside this container is not visible */
}

.main-content {
  position:relative;
  z-index:1;
  padding: 0;
  margin-top: 1%;
  min-height: 100vh; /* Take up the full viewport height */
}

p,
h1 {
  font-family: "Poppins", sans-serif;
}

.heading-container {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: #ffefb5;
  width: 100%;
  padding: 100px;
  text-align: left;
}

.sub-heading-container {
  width: 70%;
}

.main-heading {
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  font-size: 72px;
  margin: 0;
  text-align: left; /* Center the title text */
}

.sub-heading {
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  text-align: left; /* Center the subtitle text */
  margin-top: 20px; /* Add space between title and subtitle */
}

.content-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start; /* Align items to the top */
  margin-top: 60px;
  max-width: 1200px; /* Limit the width and center it */
  margin-left: auto;
  margin-right: auto;
}

.content-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 50%;
  margin-left: 60px;
}

.heading-img {
  border-radius: 10px;
}

/* Image styles */
.overall-heading-container img {
  width: 20%; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Match the border-radius from the screenshot */
}

.top-half {
  width: 100%;
  height: 50%; /* Height to cover the top half */
  background-color: #ffccbc; /* Color for the top half */
}

.overall-heading-container {
  margin-top: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: linear-gradient(
    to bottom,
    #ffefb5 0%,
    #ffefb5 50%,
    #ffffff 50%,
    #ffffff 100%
  );
}

.heading-text-container {
  display: flex;
  flex-direction: column;
  width: 40%;
  margin-right: 10px;
}

.heading-content-container {
  text-align: left;
  width: 80%;
  border: 2px solid rgb(222, 218, 218);
  border-radius: 10px;
  padding: 5px;
}

button {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
}

.heading-title-container {
  margin-bottom: 20px;
  margin-top: 30px;
  width: 100%;
}

/* Additional styling for arrow containers if needed */
.slick-prev,
.slick-next {
  background-color: transparent; /* Example to remove any background styling */
  /* Other styles as necessary */
}

.slick-prev:before,
.slick-next:before {
  color: black !important; /* Choose the color you want for the arrows */
  font-size: 40px !important;
}

/* Searchbar.js */

.search-form {
  display: flex;
  align-items: center;
}

.search-input {
  flex-grow: 1; /* Makes the input field expand to fill the form width */
  padding: 8px 10px;
  width: 300px;
  border: 0.5px solid #f5f5f5;
  font-family: Poppins, sans-serif;
  border-radius: 4px;
  font-size: 186x; /* Adjust font size as needed */
}

.search-input:focus {
  outline: none; /* Optional: Removes the default focus outline */
}

.search-bar-container {
  display: flex;
  align-items: center; /* Aligns items vertically in the center */
  justify-content: flex-start; /* Aligns items to the start of the container */
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */
}

.close {
  position: absolute;
  top: 10px;
  right: 20px;
  color: #aaa;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.search-result-item {
  display: flex;
  align-items: start;
  margin-bottom: 10px;
  background-color: transparent;
  border: none;
  text-align: left;
  cursor: pointer;
}

.search-result-item:last-child {
  margin-bottom: 0;
}

.result-image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}

.result-info {
  display: flex;
  flex-direction: column;
}

.result-info h4 {
  margin: 0;
  font-size: 16px;
}

.result-info p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.search-box-result-holder {
  display: flex;
  flex-direction: row;
}

.search-container {
  position: relative;
  width: 95%;
  margin-bottom: 1vh; 
}

.search-form {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.search-results {
  position: absolute;
  top: 100%; /* Position below the search bar */
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  max-height: 63vh;
  overflow-y: auto;
}

.search-result-item {
  cursor: pointer;
  padding: 0.75rem 1rem;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  width: 100%;
}

.search-result-item:hover {
  background-color: #f0f4ff;
  transform: translateX(5px);
}

.search-result-item:last-child {
  border-bottom: none;
}

.result-image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  margin-right: 10px;
}

.result-info {
  display: flex;
  flex-direction: column;
}

.result-info h4 {
  margin: 0;
  font-size: 16px;
}

.result-info p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure the overlay is above other content */
}

.chapter-overlay {
  position: fixed;
  color: rgb(222, 218, 218);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.85); /* Black with opacity */
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  z-index: 10; /* Ensure the overlay is above other content */
}

.chapter-holder {
  margin-top: -3rem;
}


.search-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4); /* Black with opacity */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10; /* Ensure the overlay is above other content */
}

.search-modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  position: relative;
}

.search-suggestion-modal {
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  margin: 0 auto;
  margin-top: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer; /* Change cursor to pointer on hover */
  padding: 10px 10px;
}

.close {
  cursor: pointer;
  margin-left: 10px; /* Adjust this if needed for spacing */
}

/** Places.js styling */
.map-popup {
  font-family: Poppins, sans-serif;
}

.popup-title {
  font-family: Poppins, sans-serif;
  font-size: 16px;
}

.popup-description {
  font-family: Poppins, sans-serif;
  font-size: 12px;
  margin: 0.3em 0 !important;
}

.separator {
  margin-bottom: 20px;
}

#map-container {
  z-index: 1;
}

.legend {
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 20px;
  left: 20px;
  background: transparent;
  padding: 1px;
  z-index: 1000; /* Make sure the legend is on top of the map */
}

.legend-item {
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: white;
  font-family: Poppins, sans-serif;
  padding: 5px 10px;
}

.legend-item.inactive {
  background-color: #c2c2c2;
}

.legend-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.leaflet-popup-content-wrapper {
  position: relative;
  padding: 10px;
  background: white;
  border-radius: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
}

/* DemoGallery.js styling */
.gallery-image-container {
  position: relative;
  width: 100%; /* Adjust based on your requirements */
}

.gallery-image {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 15px;
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
  opacity: 0;
  transition: opacity 0.3s ease;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.gallery-image-container:hover .image-overlay {
  opacity: 1;
}

.gallery-heading {
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 20px;
  padding: 0px 10px;
  font-weight: bold;
  margin-left: 10px;
}

.gallery-text {
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  padding: 0px 10px;
  margin-left: 10px;
}

.vault-search-container {
  position: relative;
  align-items: center;
  width: 20rem;
  margin-bottom: 1vh; 
  z-index: 100;
}

.profile-photo {
  width: 4rem;  /* You can adjust the size as needed */
  height: 4rem; /* Ensure width and height are equal for a perfect circle */
  border-radius: 50%; /* This makes the image round */
  object-fit: cover;  /* Ensures the image covers the container without distortion */
  transform: scale(1.4);
  align-items: center;
  margin-right: 2.5rem;
}

.virgil-logo {
  width: 4rem;  /* You can adjust the size as needed */
  height: 4rem; /* Ensure width and height are equal for a perfect circle */
  border-radius: 50%; /* This makes the image round */
  object-fit: cover;  /* Ensures the image covers the container without distortion */
  transform: scale(1.4);
  align-items: center;
  /* Frosted glass effect */
  background: rgba(255, 255, 255, 0.1);  /* Semi-transparent white background */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);  /* For Safari support */
  
  /* Updated border for frosted effect */
  border: 4px solid rgba(255, 255, 255, 0.1);
  
  /* Box shadow for depth */
  box-shadow: 0 4px 16px rgba(31, 38, 135, 0.2);
  
  /* Ensure content is centered */
  display: flex;
  align-items: center;
  justify-content: center;
  
  /* Smooth transition for hover effects */
  transition: all 0.3s ease;
  
  z-index: 5000;
}

.custom-arrows-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-arrow {
  background-color: #FFB347;
  color: white;
  border: none;
  font-size: 24px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.custom-arrow:hover {
  background-color: #FFCC33;
}

.prev-arrow {
  padding-right: 3px;
}

.next-arrow {
  padding-left: 3px;
}

.header-section {
  flex: 1;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-right: 2.5rem;
  justify-content: left;
  margin-left: 2.5rem;
  z-index: 1250;
}

.header-section-profile {
  display: flex;
  align-items: center;
  
  /* Frosted glass effect */
  background: rgba(240, 240, 240, 0.2);  /* Semi-transparent light background */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);  /* For Safari support */
  
  border-radius: 50px;
  padding: 1.5rem 20px;  /* Combining vertical and horizontal padding */
  
  /* Updated border for frosted effect */
  border: 1px solid rgba(204, 204, 204, 0.8);
  
  /* Box shadow for depth */
  box-shadow: 0 4px 16px rgba(31, 38, 135, 0.1);
  
  margin-top: 1.25rem;
  
  /* Ensure text is readable */
  color: rgba(0, 0, 0, 0.8);
  
  /* Smooth transition for any hover effects */
  transition: all 0.3s ease;
  z-index: 1000;
}

/* Fallback for browsers that don't support backdrop-filter */
@supports not (backdrop-filter: blur(10px)) {
  .header-section-profile {
    background: rgba(240, 240, 240, 0.9);  /* More opaque fallback */
  }
}


.header-section-profile-holder{
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-section:first-child {
  justify-content: flex-start;
}


.header-section:nth-child(2) {
  justify-content: center;
}

.header-section:last-child {
  justify-content: flex-end;
}

.demo-header-logo {
  font-family: 'Palatino Linotype', serif;
  font-size: 2.5rem;
  color: #333;
  text-decoration: none;
}

.profile-photo-holder {
  position: relative;
  top: 10px;
}

.profile-photo {
  width: 4rem;
  height: 4%;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.name-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.pre-release-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.15);  /* Slightly transparent white */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);  /* For Safari support */
  color: white;
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1000;
  border-top: 1px solid rgba(255, 255, 255, 0.2);  /* Subtle top border */
}

.banner span {
  flex-grow: 1;
}

.sign-out-button {
  background-color: #fac504;
  color: #000000;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sign-out-button:hover {
  background-color: #f5b400;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
}

/* Fallback for browsers that don't support backdrop-filter */
@supports not (backdrop-filter: blur(10px)) {
  .banner {
    background-color: rgba(0, 0, 0, 0.8);  /* More opaque fallback */
  }
}

.header-flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.search-bar-container {
  background-color: #f5f5f5;
  border-radius: 25px;
  padding: 5px 15px;
}

/* Progress bar container */
.progress-bar-container {
  width: 65% !important; /* Full width of the parent container */
  max-width: 100%; /* Ensures it doesn't exceed the width of the parent */
  height: 5px;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin-top: -1.5rem; /* Adjust vertical spacing */
  z-index:1000;
}

/* Progress bar itself */
.progress-bar {
  height: 100%;
  background-color: #ff8400;
  transition: width 0.5s ease;
}

/* Container for profile and progress bar */
.header-and-progressbar {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the items horizontally */
  transition: transform 0.65s ease-in-out;
  z-index: 1250;
}

.header-and-progressbar.shrink {
  transform: scale(0.7); /* 30% smaller */
}

.header-section.shrink {
  transform: scale(0.8);
  transition: transform 0.65s ease-in-out;
}

.center-mode-carousel .item.inactive {
  pointer-events: none;
  opacity: 0;
}

.center-mode-carousel .item.center {
  pointer-events: auto;
  opacity: 1;
}

.slick-slide {
  opacity: 0.9;
  transition: transform 0.65s ease, filter 0.65s ease;
  transform: scale(1); /* Ensure all slides start at normal size */
}

.slick-slide.slick-active {
  opacity: 0.1;
}

.slick-slide.slick-center {
  opacity: 1;
  transform: scale(1.1); /* Only scale up the center slide */
}

/* Prevent scaling of non-active slides */
.slick-slide:not(.slick-active) {
  transform: scale(1) !important;
}

.mobile-header {
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);  /* Semi-transparent white background */
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);  /* For Safari support */
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);  /* Subtle border */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);  /* Subtle shadow for depth */
}

/* Fallback for browsers that don't support backdrop-filter */
@supports not (backdrop-filter: blur(10px)) {
  .mobile-header {
    background: rgba(255, 255, 255, 0.8);  /* More opaque fallback */
  }
}

.mobile-demo-name {
  margin: 0 0 0 12px;
  font-size: 1.8rem !important;
  font-weight: 600;
  color: #ffffff !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 80px);
  line-height: 1.2;
}

.mobile-header-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-profile-section {
  display: flex;
  align-items: center;
}

.mobile-profile-photo-container {
  width: 6rem;
  height: 6rem;
  overflow: hidden;
  border-radius: 50%;
  flex-shrink: 0;
}

.mobile-profile-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mobile-demo-name {
  margin: 0 0 0 8px;
  font-size: 16px;
  color: #333;  /* Adjust color for better contrast */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100% - 70px);
}

.mobile-search-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.mobile-progress-bar-container {
  margin-top: 4px;
  background-color: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
  overflow: hidden;
}

.mobile-progress-bar {
  height: 0.8rem;
  background-color: #ff9100;
  transition: width 0.3s ease;
}