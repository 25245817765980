.carousel-card {
  width: 30vw;
  max-width: 400px; /* Add max-width for larger screens */
  height: 40vh; /* Set a fixed height */
  max-height: 400px; /* Limit maximum height */
  min-height: 300px; /* Set a minimum height */
  display: flex;
  flex-direction: column;
  border-radius: 0px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(40, 36, 36, 0.1), 0 6px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease, backdrop-filter 0.3s ease;
  background-color: white;
  padding: 1.25rem 1rem 0;
  cursor: pointer;

  /* Frosted glass effect */
  background: rgba(246, 238, 227, 0.95);
  border: 5px solid rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 16px rgba(31, 38, 135, 0.1);
}

@media (max-width: 1200px) {
  .carousel-card {
    width: 40vw;
    height: 40vh;
  }
}

@media (max-width: 768px) {
  .carousel-card {
    width: 70vw;
    height: 60vh;
  }
}

@media (max-width: 480px) {
  .carousel-card {
    width: 90vw;
    height: 70vh;
  }
}

.carousel-card:hover, .carousel-card:focus {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.1),
              0 0 20px rgba(255, 179, 0, 0.6); /* More orange golden shadow */
  filter: brightness(1.05);
  transition: transform 0.5s ease, box-shadow 0.5s ease, filter 0.5s ease; /* Smooth transition */
}

.carousel-card::after {
  content: '';
  position: absolute;
  top: -2px;
  left: -2px;
  right: -2px;
  bottom: -2px;
  border: 2px solid transparent;
  border-radius: inherit;
  background-clip: padding-box;
  opacity: 0;
  transition: opacity 0.5s ease;
  pointer-events: none;
}

.carousel-card:hover::after, .carousel-card:focus::after {
  opacity: 0.6;
}

.carousel-card-header-background {
  width: 100%;
  height: 5rem;
  background: linear-gradient(135deg, #fddfb5, #f8d773);
}

.carousel-card-image {
  width: 100%;
  height: auto; /* Allow height to adjust based on content */
  max-height: 70%; /* Increase max-height to 70% of card height */
  min-height: 40%; /* Ensure image takes at least 40% of card height */
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden; /* Hide overflow for tall images */
}

.carousel-card-image img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
  object-fit: contain;
  object-position: center;
  box-shadow: 
     0 15px 30px rgba(0, 0, 0, 0.15),
     0 5px 15px rgba(0, 0, 0, 0.1);
}

.carousel-card-body {
  flex: 1 1 auto; /* Allow flex-grow and flex-shrink */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1rem; /* Add padding to the body */
  overflow-y: auto; /* Add scrolling for overflow text */
  min-height: 30%; /* Ensure body takes at least 30% of card height */
}

.carousel-card-body-text {
  text-align: center;
  font-size: calc(0.8rem + 0.5vw);
  line-height: 1.6;
  width: 100%; /* Ensure text uses full width of body */
}

h3 {
  font-size: calc(0.9rem + 0.15vw);
  margin-bottom: 0.75rem;
}

@media (max-width: 768px) {
  .carousel-card-body-text {
    font-size: calc(0.7rem + 0.5vw);
  }

  h3 {
    font-size: calc(1rem + 1vw);
  }
}

@media (max-width: 480px) {
  .carousel-card-body-text {
    font-size: calc(0.6rem + 0.5vw);
  }

  h3 {
    font-size: calc(0.9rem + 1vw);
  }
}

/* Add space between the text and the image */
.carousel-card-image {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.2rem;
  }
}

/* Slick slider adjustments */
.slick-slide {
  transition: opacity 0.3s ease, transform 0.3s ease;

}

.slick-active {
  display: flex !important;
  justify-content: center;
}

.slick-list {
  display: flex;
  justify-content: center;
}

.slick-slide:not(.slick-active) {
  opacity: 0;
}

/* Remove the small-image class as it's no longer needed */