/* Chapters.css */

.chapter-list {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  padding: 1rem; /* Reduce padding to allow for more content space */
  max-width: 100%; /* Ensure it takes the full width of the container */
  height: 100%; /* Set height to 100% to match the video container */
  margin: 0 auto; /* Center the chapter list */
  display: flex;
  flex-direction: column;
  max-height: 80%;
}

.header {
  background-color: transparent !important;
  font-size: 1.5rem;
  font-weight: bold;
  color: #f1f1f1;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 2px solid #e0e0e0;
  flex-shrink: 0; /* Prevent header from shrinking */
}


.chapter-content {
  background: transparent;
  flex-grow: 1;
  overflow-y: auto; /* Allow scrolling if content exceeds the height */
  padding-right: 0.5rem;
  max-height: calc(100% - 2rem); /* Ensure it doesn't exceed its container's height */
}

.chapter-container {
  border: none;
  cursor: pointer;
  padding: 0.75rem 1rem;
  margin-bottom: 0.5rem;
  border-radius: 4px;
  transition: all 0.2s ease;
  display: flex;
  align-items: left;
}

.chapter-container:hover {
  background-color: #ff9100;
  transform: translateX(5px);
}

.chapter-number {
  font-weight: 1200;
  color: #f1f1f1;
  margin-right: 0.5rem;
  flex-shrink: 0;
}

.chapter-title, .snippet-title {
  color: #f1f1f1;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1.4;
  font-weight: 1200;
  font-size: larger;
}

.snippet-title {
  width: 100%;
}

.chapter-container:hover .chapter-number,
.chapter-container:hover .chapter-title {
  color: #d7954e;
}

/* Scrollbar styling */
.chapter-content::-webkit-scrollbar {
  width: 6px;
}

.chapter-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.chapter-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.chapter-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.video-background-container,
.video-container {
  border: none;
  outline: none;
}


.key-moments {
  font-size: 0.9rem;
  color: #333;
  margin-top: 0.25rem;
  border-bottom: 2px solid #e0e0e0;
  padding-bottom: 1rem;
  flex-shrink: 0; /* Prevent header from shrinking */
  margin-left: 2rem;
}

/* Remove the media query and apply mobile styles based on the .mobile class */
.chapter-list.mobile {
  width: 100%;
  max-height: 25vh;
  min-height: 15vh;
  margin: 0;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed; /* Fix the position at the bottom */
  bottom: 0; /* Align to the bottom of the screen */
  left: 0;
  right: 0;
  top: 42vh;
  z-index: 1000; /* Ensure it's above other content */
}

.chapter-content.mobile {
  max-height: none;
  overflow-y: auto;
}

.chapter-and-search.mobile {
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 60vh;
  margin-top: 10rem;
}

.video-and-toggle-container.mobile {
  width: 100%;
  height: 60vh; /* Adjust to take up the remaining 60% of the screen */
  position: relative;
}

.share-container.mobile {
  position: fixed;
  bottom: 40vh; /* Position it above the chapter list */
  left: 0;
  right: 0;
  padding: 10px;
  background-color: #000;
  z-index: 1001; /* Ensure it's above the chapter list */
}

.toggle-chapters-button.mobile {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1002; /* Ensure it's above other elements */
}