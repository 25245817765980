@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

/* General Styling */
.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  margin: 20px auto;
  margin-top: -1.5rem;
}

.location-date-heading {
  margin-top: -3rem;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.location-date-heading.mobile {
  margin-top: 0.5rem;
  margin-bottom: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center; /* Add this line */
  width: 100%; /* Add this line */
  height: 6.5vh; /* Fixed height, adjust as needed */
}

.location-date-heading h1 {
  color: #ffffff;
  font-size: clamp(12px, 5vw, 30px); /* Responsive font size */
  text-align: center;
  width: 100%;
  margin: 0.25em 0; /* Adjust vertical spacing */
}

.location-date-heading.mobile h1 {
  color: #ffffff;
  font-size: clamp(12px, 5vw, 16px); /* Responsive font size */
  text-align: center;
  width: 100%;
  margin: 0.25em 0; /* Adjust vertical spacing */
}

.location-date-heading h2 {
  color: #ffffff;
  font-size: clamp(8px, 4vw, 12px); /* Responsive font size */
  text-align: center;
  width: 80%;
  margin: 0.5em 0; /* Adjust vertical spacing */
}

.location-date-heading.mobile h2 {
  color: #ffffff;
  font-size: clamp(8px, 4vw, 12px); /* Responsive font size */
  text-align: center;
  width: 80%;
  margin: 0.5em 0; /* Adjust vertical spacing */
}

.family-heading {
  font-family: Poppins, sans-serif;
  font-weight: bold;
  font-size: 32px;
}

.family-content {
  font-family: Poppins, sans-serif;
  font-size: 20px;
  text-align: left;
  width: 60%;
}

.gray-box-chapter {
  font-family: Poppins, sans-serif;
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 15px;
  margin-bottom: 20px;
}

/* Media and Layout Containers */
.horizontal-media-container {
  display: flex;
  height: 58vh;
  width: 100%;
  justify-content: space-between;
}

.video-and-toggle-container {
  display: flex;
  flex: 1;
  position: relative;
}

.total-media-container {
  display: flex;
  flex-direction: column;
  height: 58vh;
  margin: 0;
  padding: 0;
}

.video-background-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  transition: flex 0.3s ease;
}

.video-background-container.expanded {
  flex: 1.5;
}

.video-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2.5rem;
  max-height: 100%;
  padding-top: 2.5rem;
  border: none; /* Ensure no border is applied */
  background: none; /* Ensure no background color is applied */
}

.video-container.mobile {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 100%;
  height: 100% !important;
  border: none; /* Ensure no border is applied */
  background: none; /* Ensure no background color is applied */
}

.react-player {
  width: 100%; /* Ensure the player fills its container */
  height: 100%; /* Ensure the player fills its container */
}

.chapter-and-search {
  flex: 0.5;
  overflow-y: auto;
  padding: 1rem;
  box-sizing: border-box;
  transition: flex 0.3s ease, opacity 0.3s ease;
}

.chapter-and-search.visible {
  flex: 0.5;
  opacity: 1;
}

.chapter-and-search.hidden {
  flex: 0;
  opacity: 0;
  padding: 0;
  width: 0;
  overflow: hidden;
}

/* Buttons and Navigation */
.toggle-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.toggle-chapters-button {
  position: absolute;
  top: 6px;
  right: 0.25rem !important; /* Adjust this value as needed */
  z-index: 2500;
  background-color: transparent;
  border: none;
  padding: 10px;
  border-radius: 2.5px; /* Make it a fully rounded button */
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video-background-container.expanded + .chapter-and-search + .toggle-chapters-button {
  right: 0;
}

.toggle-chapters-button:hover svg {
  color: #ff9100;
}

.back-to-life-chapters,
.nav-chapter-button {
  font-size: 16px;
  font-weight: bold;
  background-color: #ffde62;
  color: black;
  width: 15%;
  border: none;
  padding: 10px;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-bottom: 100px;
}

.back-to-life-chapters:hover,
.nav-chapter-button:hover {
  background-color: #cc8400;
}

.button-holder {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding-bottom: 1%;
}

/* Modal Styling */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.85) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
  backdrop-filter: blur(5px);
  animation: modalFadeIn 0.5s ease forwards; /* Play fade-in animation */
}

.modal-overlay.fade-out {
  animation: modalFadeOut 0.5s ease forwards; /* Play fade-out animation */
}

.modal-content {
  background: transparent !important;
  padding: 1rem;
  border-radius: 10px;
  max-width: 75vw !important;
  width: 75vw !important;
  max-height: calc(100vh - 2.5rem - 35px) !important; /* Adjust for header and padding */
  height: auto !important; /* Change to auto */
  overflow-y: visible;
  position: fixed; /* Change to fixed */
  z-index: 2500;
  top: calc(7rem + 35px); /* Position below header */
  transform: scale(1.25); /* Ensure it's at full scale */
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 0px !important;
  z-index: 2501; /* Ensure it's above other elements */
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.modal-close-button.mobile {
  position: absolute;
  top: -10px;
  right: -5px !important;
  z-index: 2501; /* Ensure it's above other elements */
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

/* Styles for non-mobile screens */
@media (min-width: 769px) {
  .toggle-chapters-button {
    right: 50px; /* Adjust this value to position it further from the right edge */
  }

  .modal-close-button {
    right: 10px; /* This will position it to the right of the toggle-chapters-button */
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .header-container.mobile {
    order: -1;
    width: 100%;
    padding: 10px;
    padding-top: 4rem;
    margin-bottom: 0;
  }

  .horizontal-media-container.mobile {
    flex-direction: column;
    height: auto; /* Let content determine height */
    flex-grow: 1;
    display: flex;
  }

  .video-and-toggle-container.mobile {
    width: 100%;
    height: 40vh; /* Adjust this value to control video size */
    position: relative;
  }

  .video-background-container.mobile {
    width: 100%;
    height: 100%;
  }

  .video-container.mobile {
    width: 100%;
    height: 100%;
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .chapter-and-search.mobile {
    width: 100%;
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    height: auto;
  }

  .toggle-chapters-button.mobile {
    position: absolute;
    top: -1rem !important;
    right: -0.25rem !important;
    z-index: 1000;
  }

  .share-container.mobile {
    width: 100%;
    padding: 10px;
    background-color: #000;
  }
}

/* Modal Fade and Zoom Animation */
@keyframes modalFadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes modalFadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 1.5s ease-in-out forwards;
}

.fade-out {
  animation: fadeOut 1.5s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.modal-content.mobile {
  width: 100%;
  max-width: 100% !important;
  height: calc(100vh - 2.5rem) !important;
  max-height: calc(100vh - 2.5rem) !important;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: #000 !important;
  top: 2.5rem;
  left: 0;
  transform: none;
}

.no-overflow-body-container.mobile {
  height: 2.5rem;
}

.chapter-main-content.mobile {
  height: 80vh !important;
}

.total-media-container.mobile {
  /* Mobile-specific styles for media container */
}

.horizontal-media-container.mobile {
  flex-direction: column;
  height: auto;
  flex-grow: 1;
}

.video-and-toggle-container.mobile {
  width: 100%;
  height: 40vh;
}

.video-background-container.mobile {
  height: 10vh; /* Adjust as needed */
}

.toggle-chapters-button.mobile {
  top: -2.5rem !important;
}

.chapter-and-search.mobile {
  width: 100%;
  margin-top: 16px;
}

.share-container.mobile {
  /* Adjust share container for mobile */
}

.header-container.mobile {
  height: 20vh;/* Mobile-specific styles fo r header */
}

.mobile-chapters-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 10;
}